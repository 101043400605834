<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      status-icon
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="产品型号" prop="productNo">
        <el-select
          v-model="ruleForm.productNo"
          placeholder="请选择"
          @change="getSelectProduct"
          filterable
          clearable
        >
          <el-option
            v-for="item in productList"
            :key="item.productNo"
            :label="item.productModel"
            :value="item.productNo"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div v-if="ruleForm.productNo" class="mt20 mb20">
        <el-table :data="tableList" style="width: 100%">
          <el-table-column  prop="productNo" fixed  min-width="200" label="产品编号" />
          <el-table-column prop="productModel" fixed min-width="150" label="产品型号" />
          <el-table-column prop="productType" label="产品类型" width="130">
            <template slot-scope="{ row }">
              {{ $changeObj(PRODUCT_TYPE)[row.productType] }}
            </template>
          </el-table-column>
          <el-table-column prop="electricVoltage" label="电池电压"/>
          <el-table-column prop="batteryCapacity" label="电池容量"/>
          <el-table-column prop="bmsSupplierName" label="BMS供应商名称" min-width="150"/>
          <el-table-column prop="gpsSupplierName" label="GPS供应商名称" min-width="150"/>
        </el-table>
      </div>
      <el-form-item
        label="设备通讯编号"
        prop="deviceIdStr"
        v-if="$route.query.type != 'edit'"
      >
        <div class="df ">
          <el-input
            type="textarea"
            :rows="5"
            style="width: 500px"
            placeholder="请输入内容"
            v-model="ruleForm.deviceIdStr"
          >
          </el-input>
          <el-upload
            class="upload-demo ml10"
            action="#"
            :limit="1"
            with-credentials
            :auto-upload="false"
            :on-change="getFile"
            :show-file-list="true"
            :before-remove="beforeRemove"
          >
            <el-button size="small" type="primary">导入</el-button>
          </el-upload>
        </div>
        <p class="c9">
          第一位是通讯板编号，
          第二位是电池二维码，用逗号分割。输入完成以后换行再次输入！
        </p>
      </el-form-item>
      <!-- 编辑 -->
      <div v-if="$route.query.type == 'edit'">
        <el-form-item label="设备编号"> {{ query.deviceNo }}</el-form-item>
        <el-form-item label="通讯板编号"> {{ query.deviceId }}</el-form-item>
        <el-form-item label="电池二维码">
          <div class="df">
            <el-input
              type="text"
              style="width: 200px"
              placeholder="请输入电池二维码"
              v-model="query.linkNo"
            >
            </el-input>
            <el-upload
              class="upload-demo ml10"
              action="#"
              :limit="1"
              :disabled="uploadDisabeld"
              with-credentials
              :auto-upload="false"
              :on-change="getQrcode"
              :show-file-list="false"
            >
              <el-button size="small" type="primary" :disabled="uploadDisabeld"
                >上传二维码</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm" :loading="disabled"
          >确认</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const urlObj = {
  'after':'/leaseholdMgmt/afterSaleBatteryList',
  'list':'/leaseholdMgmt/batteryList',
  'new':'/leaseholdMgmt/newAddBatteryList'
}
import { addNewBattery, saveEdit, getWhiteList } from "@/api/facility";
import { getProductList } from "@/api/product";
import { PRODUCT_TYPE } from "@/js/constant";
import axios from "axios";
import QrcodeDecoder from "qrcode-decoder";
export default {
  data() {
    return {
      productList: [],
      tableList: [],
      PRODUCT_TYPE,
      ruleForm: {
        productNo: "",
        deviceIdStr: ""
      },
      rules: {
        productNo: [
          { required: true, message: "请选择产品型号", trigger: "change" }
        ],
        deviceIdStr: [
          {
            required: true,
            message: "请输入用逗号隔开的设备号和编号",
            trigger: "blur"
          }
        ]
      },
      query: {
        deviceNo: "",
        productNo: "",
        linkNo: "",
        deviceId: ""
      },
      disabled: false,
      uploadDisabeld: false,
      /** 白名单 */
      whiteList: [],
      productNo: ""
    };
  },
  
  mounted() {
    this.getProductList();
    this.getWhiteList();
    if (this.$route.query.type == "edit") {
      this.query = this.$route.query;
      this.ruleForm.productNo = this.query.productNo;
    }
  },
  methods: {
    // 删除上传
    beforeRemove(){
      this.ruleForm.deviceIdStr = '';
    },
    async getProductList() {
      const params = {
        pageSize: 500,
        beginIndex: 0,
        productType: 1
      };
      const res = await getProductList(params);
      this.productList = res.ldata;
      if (this.$route.query.type == "edit") {
        this.getTable(this.query.productNo);
      }
    },
    /** 获取选择的产品信息 */
    getSelectProduct(no) {
      this.getTable(no);
    },
    getTable(productNo) {
      const row = this.productList.find(el => productNo == el.productNo);
      this.tableList = [row];
    },
    submitForm() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.disabled) {
            return;
          }
          this.disabled = true;
          try {
            /**编辑 */
            if (this.$route.query.type == "edit") {
              const params = {
                haiLiDeviceNo: this.query.deviceNo,
                productNo: this.ruleForm.productNo,
                linkNo: this.query.linkNo
              };
              await saveEdit(params);
               this.disabled = false
              this.$message.success("操作成功");
              this.$router.push(urlObj[this.$route.query.routerType]);
              // if(this.$route.query.routerType === 'new'){
              //   this.$router.push('/leaseholdMgmt/newAddBatteryList');
              // }else{
              //   this.$router.push('/leaseholdMgmt/batteryList');
              // }
            
            } else {
              /**新增 */
              const deviceArr = this.ruleForm.deviceIdStr.split("\n");
              const arr = [];
              deviceArr.forEach(el => {
                if (el) {
                  //   const str = el.replace(/，/g, ",");
                  const strArr = el.replace("，", ",").split(",");
                  arr.push({ deviceId: strArr[0], linkNo: strArr[1] });
                }
              });
              const params = {
                ...this.ruleForm,
                deviceIdStr: JSON.stringify(arr)
              };
              await addNewBattery(params);
            }
            this.disabled = false;
            this.$message.success("操作成功");
            this.$router.push(urlObj[this.$route.query.routerType]);
          } catch (error) {
            this.disabled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      
      this.$router.push(urlObj[this.$route.query.routerType]);

    },
    getFile(file) {
      const formData = new FormData();
      formData.append("excelFile", file.raw);
      let config = {
        "Content-Type": "multipart/form-data"
      };
      axios
        .post("/managementPlatform/device/parseExcel", formData, config)
        .then(res => {
          res.data.ldata.forEach(el => {
            this.ruleForm.deviceIdStr += `${el.deviceId},${el.linkNo}\n`;
          });
        });
    },
    // 编辑
    // 解析二维码
    getQrcode(newFile) {
      this.uploadDisabeld = true;
      const file = newFile.raw;
      let url = null;
      if (window.createObjectURL) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      const qr = new QrcodeDecoder();
      qr.decodeFromImage(url)
        .then(res => {
          /** 解析qrcode */
          const strArr = res.data.split("?qrcode=");
          const domain = strArr[0];
          /**解析的域名是否在白名单 */
          const flag = this.whiteList.some(el => domain.includes(el));
          if (!flag) {
            this.$message.error("无效二维码");
            this.uploadDisabeld = false;
            return;
          }
          const linkNoArr = strArr[1].split("&version=1.0");
          this.query.linkNo = linkNoArr[0];
          this.uploadDisabeld = false;
          this.$message.success('解析成功')
        })
        .catch(err => {
          console.log(err);
          this.$message.error("识别失败");
          this.uploadDisabeld = false;
        });
    },
    /*获取白名单 */
    async getWhiteList() {
      const res = await getWhiteList({});
      this.whiteList = res.ldata;
    }
  },
  components: {}
};
</script>

<style scoped></style>
